<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.linkedin.com/in/julius-rosenberg/"
        target="_blank"
        >Julius Rosenberg</b-link
      >
      <!-- <span class="d-none d-sm-inline-block">, All rights Reserved</span> -->
    </span>

    <span class="float-md-right d-none d-md-block"
      >A project by
      <a href="https://rosenbergitkollektiv.de/asap-data" target="_blank">
        <b-img
          :src="require('@/assets/images/footer/symbol.svg')"
          alt="project image"
        />
      </a>
    </span>
  </p>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BImg,
  },
};
</script>
